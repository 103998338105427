export const getInitials = (name = '') => {
  const dayAbbreviations = {
      monday: 'M',
      tuesday: 'T',
      wednesday: 'W',
      thursday: 'Th',
      friday: 'F',
      saturday: 'Sa',
      sunday: 'Su',
    };

  const words = name.split(' ');

  if (words.length === 0) {
    return '';
  }

  const firstInitial = dayAbbreviations[words[0].toLowerCase().replace(',', '')] || '';

  return `${firstInitial}`;
};

