import TrashIcon from '@heroicons/react/24/solid/TrashIcon';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import backendPort from "../port";


function DeleteSurveyButton(survey) {
    const navigate = useNavigate();
    function handleDeleteSurveyClick(survey) {
        console.log(survey);
        const confirmation = window.confirm('Are you sure you want to delete the ' + survey.survey.date + ' ' + survey.survey.time + ' survey?');
        console.log(survey.survey);
        if (confirmation) {
            // If the user confirms the alert, refresh the page
            axios.post(`${backendPort}/adminSurveys/delete`, survey)
                .then(response => {
                    // Handle success, e.g., show a success message or update the UI
                    console.log('Survey deleted successfully');

                    window.location.reload();
                })
                .catch(error => {
                    console.error(error);
                    // Handle the error, e.g., show an error message to the user
                });
            window.location.reload();
        }

    }
    return(
    <Button onClick={() => handleDeleteSurveyClick(survey)}
            startIcon={(
                <SvgIcon fontSize="small">
                    <TrashIcon/>
                </SvgIcon>
            )}
            variant="contained"
            style={{backgroundColor: '#C41E3A', color: 'white'}}
    >
        Delete Survey
    </Button>
    );
}

export default DeleteSurveyButton;
