import NavBar from "../components/NavBar";
import React from "react";
import '../styles/SubmissionPage.css'; // Import a CSS file for styling

function SubmissionPage() {
  return (
    <div>
        <NavBar></NavBar>
        <div className="submission-container">
        <div className="submission-content">
            <h2>Thank you for your submission!</h2>
            <p>We'll see you soon for the activities!</p>
        </div>
        </div>
    </div>
  );
}

export default SubmissionPage;