import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";

import Navbar from '../components/NavBar';
import { useEffect, useState } from "react";
import axios from 'axios'; 
import backendPort from '../port';


const keywordQuestionMapping = {
    'businessArea': 'What business area are you primarily interested in?',
    'continent': 'What continent were you born on?', 
    'dancing': 'What\'s your favorite type of dancing?', 
    'detail': 'Are you more detail-oriented or a big-picture person?', 
    'eldestChild': 'Are you the eldest child in your family?', 
    'extrovert': 'Would you describe yourself as an extrovert or introvert?', 
    'favoriteMusic': 'What\'s your favorite type of music?', 
    'favoriteWeather': 'What type of weather do you prefer?', 
    'highSchool': 'What type of High School education did you have?', 
    'industry': 'What industry are you most interested in working in?', 
    'interactionTypes': 'Do you prefer one-on-one interactions or larger group gatherings?', 
    'internationalJob': 'Are you ever interested in working an international job?', 
    'languages': 'How many languages do you speak?', 
    'morning': 'Are you a morning or night person?', 
    'musicalInstrument': 'What type of musical instrument can you play? (if you play multiple, select the one you are most proficient in)', 
    'ownCompany': 'Do you want to create your own company or work for an existing company?', 
    'politicalParty': 'What political party do you identify with the most?', 
    'risk': 'Would you describe yourself as a risk-taker or risk-avoider?', 
    'siblings': 'How many siblings do you have?', 
    'spender': 'Are you a spender or saver?', 
    'studyTimes': 'How many hours per week do you typically spend studying during a semester?', 
    'volunteer': 'Do you participate in any volunteer or community service activties?', 
    'youngestChild': 'Are you the youngest child in your family?',     
}; 

  
// const [currSurvey] = useState(JSON.parse(localStorage.getItem("survey")));

// const tableData = [
//     {
//         title: "Are you the Eldest Child?",
//         data: [
//             { name: "Yes", Instrument: 50 },
//             { name: "No", Instrument: 45 },
//         ],
//         color: "#FF5733"
//     },
//     {
//         title: "Are you the Youngest Child",
//         data: [
//             { name: "Yes", Instrument: 50 },
//             { name: "No", Instrument: 45 },
//         ],
//         color: "#33FF57"
//     },
//     {
//         title: "What Continent were you born in?",
//         data: [
//             { name: "Asia", Instrument: 9 },
//             { name: "North America", Instrument: 7 },
//             { name: "South America", Instrument: 6 },
//             { name: "Africa", Instrument: 8 },
//             { name: "Europe", Instrument: 9 },
//             { name: "Antarctica", Instrument: 7 },
//             { name: "Australia", Instrument: 6 },
//         ],
//         color: "#3366FF"
//     },
//     {
//         title: "How Many Siblings do you have?",
//         data: [
//             { name: "0", Instrument: 7 },
//             { name: "1-2", Instrument: 8 },
//             { name: "3+", Instrument: 9 },
//         ],
//         color: "#FF33B8"
//     },
//     {
//         title: "How many languages do you speak?",
//         data: [
//             { name: "1", Instrument: 8 },
//             { name: "2", Instrument: 9 },
//             { name: "3", Instrument: 9 },
//             { name: "4+", Instrument: 9 },
//         ],
//         color: "#FFD700"
//     },
//     {
//         title: "Would you describe yourself as an extrovert or introvert?",
//         data: [
//             { name: "Extrovert", Instrument: 7 },
//             { name: "Introvert", Instrument: 8 },
//             { name: "Ambivert", Instrument: 9 },
//         ],
//         color: "#00FFD7"
//     },
//     {
//         title: "Would you describe yourself as a risk-taker or risk-avoider?",
//         data: [
//             { name: "Risk-taker", Instrument: 7 },
//             { name: "Risk-avoider", Instrument: 8 },
//         ],
//         color: "#FFD733"
//     },
//     {
//         title: "Are you a spender or saver?",
//         data: [
//             { name: "Spender", Instrument: 7 },
//             { name: "Saver", Instrument: 8 },
//         ],
//         color: "#33FFD7"
//     },
//     {
//         title: "What type of High School education did you have",
//         data: [
//             { name: "Home-schooled", Instrument: 7 },
//             { name: "Private", Instrument: 8 },
//             { name: "Public", Instrument: 8 },
//         ],
//         color: "#D733FF"
//     },
//     {
//         title: "Are you more detail-oriented or big-picture?",
//         data: [
//             { name: "Detail-oriented", Instrument: 7 },
//             { name: "Big-picture", Instrument: 8 },
//         ],
//         color: "#FF33F9"
//     },
//     {
//         title: "Do you participate in any volunteer or community service activities?",
//         data: [
//             { name: "Yes", Instrument: 7 },
//             { name: "No", Instrument: 8 },
//         ],
//         color: "#33FFF4"
//     },
//     {
//         title: "Do you prefer one-on-one interactions or larger group gatherings?",
//         data: [
//             { name: "One-on-one interactions", Instrument: 7 },
//             { name: "Larger group gatherings", Instrument: 8 },
//         ],
//         color: "#F933FF"
//     },
//     {
//         title: "What type of weather do you prefer",
//         data: [
//             { name: "Warm and sunny", Instrument: 7 },
//             { name: "Overcast and rainy", Instrument: 8 },
//             { name: "Cold and snowy", Instrument: 8 },
//         ],
//         color: "#FF3355"
//     },
//     {
//         title: "How many hours per week do you typically spend studying during a semester?",
//         data: [
//             { name: "0-5 hours", Instrument: 7 },
//             { name: "5-9 hours", Instrument: 8 },
//             { name: "10+ hours", Instrument: 8 },
//         ],
//         color: "#33FF99"
//     },
//     {
//         title: "Are you a morning or night person?",
//         data: [
//             { name: "Morning", Instrument: 7 },
//             { name: "Night", Instrument: 8 },
//         ],
//         color: "#3399FF"
//     },
//     {
//         title: "Do you want to create your own company or work for an existing company?",
//         data: [
//             { name: "Create my own company", Instrument: 7 },
//             { name: "Work for an existing company", Instrument: 8 },
//             { name: "Not sure", Instrument: 8 },
//         ],
//         color: "#FF33F9"
//     },
//     {
//         title: "What's your favorite type of dancing",
//         data: [
//             { name: "Ballet", Instrument: 7 },
//             { name: "Ballroom", Instrument: 8 },
//             { name: "Hip Hop", Instrument: 8 },
//             { name: "Ethnic", Instrument: 7 },
//             { name: "Line/Square Dancing", Instrument: 8 },
//             { name: "Rock Dancing", Instrument: 8 },
//             { name: "Don't dance", Instrument: 8 },
//         ],
//         color: "#F9FF33"
//     },
//     {
//         title: "What type of musical instrument can you play?",
//         data: [
//             { name: "Piano", Instrument: 7 },
//             { name: "Guitar", Instrument: 8 },
//             { name: "Brass", Instrument: 8 },
//             { name: "Percussion", Instrument: 7 },
//             { name: "Woodwind", Instrument: 8 },
//             { name: "Strings", Instrument: 8 },
//             { name: "None", Instrument: 8 },
//         ],
//         color: "#3399FF"
//     },
//     {
//         title: "What's your favorite type of music",
//         data: [
//             { name: "Rap", Instrument: 7 },
//             { name: "Pop", Instrument: 8 },
//             { name: "Country", Instrument: 8 },
//             { name: "Rock", Instrument: 7 },
//             { name: "Classical/Jazz", Instrument: 8 },
//             { name: "Folk/Indie", Instrument: 8 },
//         ],
//         color: "#FF3399"
//     },
//     {
//         title: "What political party do you identify with the most?",
//         data: [
//             { name: "Democratic", Instrument: 7 },
//             { name: "Republican", Instrument: 8 },
//             { name: "Independent", Instrument: 8 },
//         ],
//         color: "#33FFD7"
//     },
//     {
//         title: "Are you ever interested in working an international job?",
//         data: [
//             { name: "Yes", Instrument: 7 },
//             { name: "No", Instrument: 8 },
//         ],
//         color: "#D7FF33"
//     },
//     {
//         title: "What industry are you most interested in working in?",
//         data: [
//             { name: "Finance", Instrument: 7 },
//             { name: "Technology", Instrument: 8 },
//             { name: "Education", Instrument: 8 },
//             { name: "Health", Instrument: 7 },
//             { name: "Media", Instrument: 8 },
//             { name: "Nonprofit", Instrument: 8 },
//         ],
//         color: "#33FFD7"
//     },
//     {
//         title: "What business area are you primarily interested in?",
//         data: [
//             { name: "Entrepreneurship", Instrument: 7 },
//             { name: "Finance", Instrument: 8 },
//             { name: "Human Resources", Instrument: 8 },
//             { name: "Logistics", Instrument: 7 },
//             { name: "Management/Leadership", Instrument: 8 },
//             { name: "Marketing", Instrument: 8 },
//             { name: "Operations", Instrument: 8 },
//             { name: "Social Entrepreneurship", Instrument: 8 },
//             { name: "Technology", Instrument: 8 },
//         ],
//         color: "#FF336D"
//     }
// ];

function transformItem(key, item) {
    return {
      title : keywordQuestionMapping[key] || `What ${key} do you prefer`,
      data: Object.entries(item).map(([response, count]) => ({
        name: response,
        Instrument: count,
      })),
      color: "#" + (Math.random().toString(16) + "000000").slice(2, 8),
    };
  }

export default function ViewEntriesPage() {

    const [tableData, setTableData] = useState([]); // Initialize tableData as a state variable

    useEffect(() => {
        // Function to call when the component is loaded
        let survey_info = JSON.parse(localStorage.getItem("survey")); 
        let survey_id = survey_info["survey"]["_id"]; 
        getCounts(survey_id);
    }, []);

    const getCounts = async(survey_id) => {
        const count_params = {
            survey_id_val: {survey_id } 
        }; 

        try {
            const post_ping = await axios.get(`${backendPort}/countResults`, {params: count_params}); 
            //const post_ping2 = await axios.get(`${backendPort}/generateTeams`, {params: count_params})
            console.log('attempting to call generate teams'); 

            delete post_ping.data['FirstName']; 
            delete post_ping.data['LastName']; 
            delete post_ping.data['email']; 

            const transformedData = Object.entries(post_ping.data).map(([key, item]) =>
                transformItem(key, item)
            );

            setTableData(transformedData); 
        }catch (error) {
            console.error('Error retrieving survey', error);
        }
    };

    return (
        <div>
            <Navbar />
            <div className="container mx-auto p-4">
                <h1 className="text-3xl font-semibold mb-4">Survey Responses </h1>
                {tableData.map((table, index) => (
                    <div key={index} className="mb-6">
                        <h2 className="text-xl font-semibold mb-4 text-center">{table.title}</h2>
                        <div className="bg-white rounded-lg shadow p-4">
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart
                                    data={table.data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="Instrument" fill={table.color} />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

