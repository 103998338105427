import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { Scrollbar } from '../components/Scrollbar';
import { getInitials } from './GetInitials';
import ViewEntriesButton from '../components/ViewEntriesButton';
import ViewTeamsButton from '../components/ViewTeamsButton';
import DeleteSurveyButton from '../components/DeleteSurveyButton';
import {useEffect, useState} from "react";
import CreateTeamsButton from "../components/CreateTeamsButton";

function SurveysTable(props) {
  const {
    count = 0,
    items = [],
    onDeselectAll,
    onDeselectOne,
    onPageChange = () => {
    },
    onRowsPerPageChange,
    onSelectAll,
    onSelectOne,
    page = 0,
    rowsPerPage = 0,
    selected = []
  } = props;

  const [created, setCreated] = useState(false);
  // Function to fetch surveys from the backend
  // const fetchCreatedBoolean = async () => {
  //     try {
  //         // Make an API call to fetch surveys here
  //         const response = await fetch('/StudentSurveys');
  //
  //     if (response.ok) {
  //         const data = await response.json();
  //         if(data.result){
  //          setCreated(true);
  //         }
  //         else{
  //          setCreated(false)
  //         }
  //     } else {
  //         // Handle API error
  //         console.error('Failed to fetch created');
  //     }
  // } catch (error) {
  //     console.error('Error:', error);
  // }
  // };
  //

  useEffect(() => {
    // Fetch surveys when the component mounts

    //fetchCreatedBoolean();
  }, []);

  const selectedSome = (selected.length > 0) && (selected.length < items.length);
  const selectedAll = (items.length > 0) && (selected.length === items.length);

  return (
    <Card>
      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: '22px', fontWeight: 'bold'}}>
                    Class Date
                </TableCell>
                <TableCell sx={{ fontSize: '22px', fontWeight: 'bold'}}>
                    Time
                </TableCell>
                <TableCell sx={{ fontSize: '22px', fontWeight: 'bold'}}>
                    Location
                </TableCell>
                <TableCell>

                </TableCell>
                <TableCell>

                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((survey) => {
                const isSelected = selected.includes(survey._id);

                return (
                  <TableRow
                    hover
                    key={survey._id}
                    selected={isSelected}
                  >
                    <TableCell>
                      <Stack
                        alignItems="center"
                        direction="row"
                        spacing={2}
                      >
                        <Avatar src={survey.avatar} sx={{ width: 60, height: 60, backgroundColor: '#C41E3A'}}>
                          {getInitials(survey.date)}
                        </Avatar>
                        <Typography variant="subtitle2" sx={{ fontSize: '18px', fontWeight: 'bold'}}>
                          {survey.date}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" sx={{ fontSize: '18px', fontWeight: 'bold'}}>
                          {survey.time}
                        </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" sx={{ fontSize: '18px', fontWeight: 'bold'}}>
                          {survey.elc}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <ViewEntriesButton survey={survey}></ViewEntriesButton>
                    </TableCell>
                    { survey.createdTeams ? (
                        <TableCell>
                          <ViewTeamsButton survey={survey}></ViewTeamsButton>
                        </TableCell>
                        ) : (
                        <TableCell>
                          <CreateTeamsButton survey={survey}></CreateTeamsButton>
                        </TableCell>
                    )}
                    <TableCell>
                      <DeleteSurveyButton survey={survey}></DeleteSurveyButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

SurveysTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onDeselectOne: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectOne: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selected: PropTypes.array
};

export default SurveysTable;