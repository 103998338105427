import NavBar from "../components/NavBar";
import {useNavigate} from "react-router-dom";
import {Model} from "survey-core";
import {useCallback, useState} from "react";
import {Survey} from "survey-react-ui";
import axios from "axios";
import backendPort from "../port"

const surveyJson = {
    elements: [{
        name:"groupRange",
        title: "Desired Group Size Range:",
        type: "panel",
        elements:[
            {
                name: "groupMin",
                title: "Minimum Group Size:",
                type: "text",
                isRequired: true
            },{
                name: "groupMax",
                title: "Maximum Group Size:",
                type: "text",
                isRequired: true
            },
            {
                name: "numTeams",
                title: "Desired number of Teams:",
                type: "text",
                isRequired: true
            }
        ],
        isRequired: true
    }, {
        name: "firstFactor",
        title: "First Ranking Factor",
        type: "panel",
        elements: [
            {
                name: "ranking",
                title: "What Factor Do You Want to Group By?",
                type: "dropdown",
                choices: [
                    {value: "Eldest Child", text: "Eldest Child"},
                    {value: "Youngest Child", text: "Youngest Child"},
                    {value: "Continent Born On", text: "Continent Born On"},
                    {value: "Number of Siblings", text: "Number of Siblings"},
                    {value: "Number of Languages Spoken", text: "Number of Languages Spoken"},
                    {value: "Introverts/Extroverts", text: "Introverts/Extroverts"},
                    {value: "Risk-Takers/Risk-Avoiders", text: "Risk-Takers/Risk-Avoiders"},
                    { value: "Spenders/Savers", text: "Spenders/Savers" },
                    { value: "Type of High School Education", text: "Type of High School Education" },
                    { value: "Detailed-Oriented/Big-Picture People", text: "Detailed-Oriented/Big-Picture People" },
                    { value: "Volunteers", text: "Volunteers" },
                    { value: "Preferred Type of Interaction", text: "Preferred Type of Interaction" },
                    { value: "Preferred Type of Weather", text: "Preferred Type of Weather" },
                    { value: "Amount Studied Each Week", text: "Amount Studied Each Week" },
                    { value: "Morning/Night Person", text: "Morning/Night Person" },
                    { value: "Create Company/Work for Existing Company", text: "Create Company/Work for Existing Company" },
                    { value: "Favorite Type of Dancing", text: "Favorite Type of Dancing" },
                    { value: "Most Proficient Musical Instrument", text: "Most Proficient Musical Instrument" },
                    { value: "Favorite Type of Music", text: "Favorite Type of Music" },
                    { value: "Political Party", text: "Political Party" },
                    { value: "Interest in an International Job", text: "Interest in an International Job" },
                    { value: "Industry of Interest", text: "Industry of Interest" },
                    { value: "Business Area of Interest", text: "Business Area of Interest" },
                ],
                id: 1,
                isRequired: true
            },
            {
                name: "Eldest Child Option0",
                title: "Group of Eldest Children or Non-Eldest Children?",
                type: "dropdown",
                id: 2,
                visibleIf: '{ranking} == "Eldest Child"',
                choices: [
                    {value: "Yes", text: "Eldest Children"},
                    {value: "No", text: "Non-Eldest Child"},
                ]
            },
            {
                name: "Youngest Child Option0",
                title: "Group of Youngest Children or Non-Youngest Children?",
                type: "dropdown",
                id: 3,
                visibleIf: '{ranking} == "Youngest Child"',
                choices: [
                    {value: "Yes", text: "Youngest Children"},
                    {value: "No", text: "Non-Youngest Child"},
                ]
            },
            {
                name: "Continent Born On Option0",
                title: "Group of People From What Continent?",
                type: "dropdown",
                id: 4,
                visibleIf: '{ranking} == "Continent Born On"',
                choices: [
                    {value: "Asia", text: "Asia"},
                    {value: "North America", text: "North America"},
                    {value: "South America", text: "South America"},
                    {value: "Africa", text: "Africa"},
                    {value: "Europe", text: "Europe"},
                    {value: "Antarctica", text: "Antarctica"},
                    {value: "Australia", text: "Australia"},
                ],
            },
            {
                name: "siblingsOption0",
                title: "Group of People With How Many Siblings?",
                type: "dropdown",
                id: 5,
                visibleIf: '{ranking} == "Number of Siblings"',
                choices: [
                    {value: "0", text: "0 Siblings"},
                    {value: "1-2", text: "1-2 Siblings"},
                    {value: "3+", text: "3+ Siblings"},
                ],
            },
            {
                name: "languagesOption0",
                title: "Group of People Able to Speak How Many Languages?",
                type: "dropdown",
                id: 6,
                visibleIf: '{ranking} == "Number of Languages Spoken"',
                choices: [
                    {value: "1", text: "1 Language"},
                    {value: "2", text: "2 Languages"},
                    {value: "3", text: "3 Languages"},
                    {value: "4+", text: "4+ Languages"},
                ],
            },
            {
                name: "extrovertOption0",
                title: "Group Of Introverts, Extroverts, or Ambiverts?",
                type: "dropdown",
                id: 7,
                visibleIf: '{ranking} == "Introverts/Extroverts"',
                choices: [
                    {value: "Extrovert", text: "Extroverts"},
                    {value: "Introvert", text: "Introverts"},
                    {value: "Ambivert", text: "Ambiverts"},
                ],
            },
            {
                name: "riskOption0",
                title: "Group of Risk-Takers or Risk-Avoiders?",
                type: "dropdown",
                id: 8,
                visibleIf: '{ranking} == "Risk-Takers/Risk-Avoiders"',
                choices: [
                    {value: "Risk-taker", text: "Risk-Takers"},
                    {value: "Risk-avoider", text: "Risk-Avoiders"},
                ],
            },{
                name: "spenderOption0",
                title: "Group of Savers or Spenders?",
                type: "dropdown",
                id: 9,
                visibleIf: '{ranking} == "Spenders/Savers"',
                choices: [
                    { value: "Spender", text: "Spenders" },
                    { value: "Saver", text: "Savers" },
                ],
            },
            {
                name: "highSchoolOptio0n",
                title: "Group of People with What High School Education?",
                type: "dropdown",
                id: 10,
                visibleIf: '{ranking} == "Type of High School Education"',
                choices: [
                    { value: "Home-schooled", text: "Home-schooled" },
                    { value: "Private", text: "Private" },
                    { value: "Public", text: "Public" },
                ],
            },
            {
                name: "detailOption0",
                title: "Group of Detail-Oriented or Big-Picture People?",
                type: "dropdown",
                id: 11,
                visibleIf: '{ranking} == "Detailed-Oriented/Big-Picture People"',
                choices: [
                    { value: "Detail-oriented", text: "Detail-Oriented" },
                    { value: "Big-picture", text: "Big-Picture" },
                ],
            },
            {
                name: "volunteerOption0",
                title: "Group of Volunteers or Non-Volunteers?",
                type: "dropdown",
                id: 12,
                visibleIf: '{ranking} == "Volunteers"',
                choices: [
                    { value: "Yes", text: "Volunteers" },
                    { value: "No", text: "Non-Volunteers" },
                ],
            },
            {
                name: "interactionOption0",
                title: "Group of People that Prefer What Type of Interaction?",
                type: "dropdown",
                id: 13,
                visibleIf: '{ranking} == "Preferred Type of Interaction"',
                choices: [
                    { value: "One-on-one interactions", text: "One-on-one interactions" },
                    { value: "Larger group gatherings", text: "Larger group gatherings" },
                ],
            },
            {
                name: "weatherOption0",
                title: "Group of People with Which Preferred Type of Weather?",
                type: "dropdown",
                id: 14,
                visibleIf: '{ranking} == "Preferred Type of Weather"',
                choices: [
                    { value: "Warm and sunny", text: "Warm and sunny" },
                    { value: "Overcast and rainy", text: "Overcast and rainy" },
                    { value: "Cold and snowy", text: "Cold and snowy" },
                ],
            },{
                name: "studyOption0",
                title: "Group of People That Study How Much a Week?",
                type: "dropdown",
                id: 15,
                visibleIf: '{ranking} == "Amount Studied Each Week"',
                choices: [
                    { value: "0-5 hours", text: "0-5 hours" },
                    { value: "5-9 hours", text: "5-9 hours" },
                    { value: "10+ hours", text: "10+ hours" },
                ],
            },
            {
                name: "morningOption0",
                title: "Group of Morning or Night People?",
                type: "dropdown",
                id: 16,
                visibleIf: '{ranking} == "Morning/Night Person"',
                choices: [
                    { value: "Morning", text: "Morning People" },
                    { value: "Night", text: "Night Owls" },
                ],
            },
            {
                name: "companyOption0",
                title: "Group Wanting to Start Their Own Company or Work for an Existing Company?",
                type: "dropdown",
                id: 17,
                visibleIf: '{ranking} == "Create Company/Work for Existing Company"',
                choices: [
                    { value: "Create my own company", text: "Create Company" },
                    { value: "Work for an existing company", text: "Work for Company" },
                    { value: "Not sure", text: "Not sure" },
                ],
            },{
                name: "dancingOption0",
                title: "Group of People with What Favorite Type of Dancing?",
                type: "dropdown",
                id: 18,
                visibleIf: '{ranking} == "Favorite Type of Dancing"',
                choices: [
                    { value: "Ballet", text: "Ballet" },
                    { value: "Ballroom", text: "Ballroom" },
                    { value: "Hip Hop", text: "Hip Hop" },
                    { value: "Ethnic", text: "Ethnic" },
                    { value: "Line/Square Dancing", text: "Line/Square Dancing" },
                    { value: "Rock Dancing", text: "Rock Dancing" },
                    { value: "Don't dance", text: "Don't dance" },
                ],
            },
            {
                name: "instrumentOption0",
                title: "Group of People Able to Play What Musical Instrument?",
                type: "dropdown",
                id: 19,
                visibleIf: '{ranking} == "Most Proficient Musical Instrument"',
                choices: [
                    { value: "Piano", text: "Piano" },
                    { value: "Guitar", text: "Guitar" },
                    { value: "Brass", text: "Brass" },
                    { value: "Percussion", text: "Percussion" },
                    { value: "Woodwind", text: "Woodwind" },
                    { value: "Strings (classical)", text: "Strings (classical)" },
                    { value: "diverse", text: "Quintet" },
                    { value: "None", text: "None" },
                ],
            },
            {
                name: "musicOption0",
                title: "Group of People with What Favorite Type of Music?",
                type: "dropdown",
                id: 20,
                visibleIf: '{ranking} == "Favorite Type of Music"',
                choices: [
                    { value: "Rap", text: "Rap" },
                    { value: "Pop", text: "Pop" },
                    { value: "Country", text: "Country" },
                    { value: "Rock", text: "Rock" },
                    { value: "Classical/Jazz", text: "Classical/Jazz" },
                    { value: "Folk/Indie", text: "Folk/Indie" },
                ],
            },{
                name: "partyOption0",
                title: "Group of People with What Political Party Affiliation?",
                type: "dropdown",
                id: 21,
                visibleIf: '{ranking} == "Political Party"',
                choices: [
                    { value: "Democratic", text: "Democratic" },
                    { value: "Republican", text: "Republican" },
                    { value: "Independent", text: "Independent" },
                ],
            },
            {
                name: "internationalJobOption0",
                title: "Group of People Interested or Not Interested in an International Job?",
                type: "dropdown",
                id: 22,
                visibleIf: '{ranking} == "Interest in an International Job"',
                choices: [
                    { value: "Yes", text: "Interested" },
                    { value: "No", text: "Not Interested" },
                ],
            },
            {
                name: "industryOption0",
                title: "Group of People with What Industry of Interest?",
                type: "dropdown",
                id: 23,
                visibleIf: '{ranking} == "Industry of Interest"',
                choices: [
                    { value: "Finance", text: "Finance" },
                    { value: "Technology", text: "Technology" },
                    { value: "Education", text: "Education" },
                    { value: "Health", text: "Health" },
                    { value: "Media", text: "Media" },
                    { value: "Nonprofit", text: "Nonprofit" },
                ],
            },
            {
                name: "businessAreaOption0",
                title: "Group of People with What Business Area of Interest?",
                type: "dropdown",
                id: 24,
                visibleIf: '{ranking} == "Business Area of Interest"',
                choices: [
                    { value: "Entrepreneurship", text: "Entrepreneurship" },
                    { value: "Finance", text: "Finance" },
                    { value: "Human Resources", text: "Human Resources" },
                    { value: "Logistics", text: "Logistics" },
                    { value: "Management/Leadership", text: "Management/Leadership" },
                    { value: "Marketing", text: "Marketing" },
                    { value: "Operations", text: "Operations" },
                    { value: "Social Entrepreneurship", text: "Social Entrepreneurship" },
                    { value: "Strategy", text: "Strategy" },
                    { value: "Technology", text: "Technology" },
                ],
            },
            {
                name: "rankingOptionDistributed0",
                title: "Do You Want These People On the Same Team or Distributed Across the Teams?",
                type: "dropdown",
                id: 100,
                isRequired: true,
                choices: [
                    {value: "Same", text: "All on the Same Team"},
                    {value: "Distributed", text: "Distributed Across All Teams"},
                ]
            },
        ]
        }, {
        name: "secondFactor",
        title: "Second Ranking Factor",
        type: "panel",
        elements: [
            {
                name: "ranking1",
                title: "What Factor Do You Want to Group By?",
                type: "dropdown",
                choices: [
                    {value: "Eldest Child", text: "Eldest Child"},
                    {value: "Youngest Child", text: "Youngest Child"},
                    {value: "Continent Born On", text: "Continent Born On"},
                    {value: "Number of Siblings", text: "Number of Siblings"},
                    {value: "Number of Languages Spoken", text: "Number of Languages Spoken"},
                    {value: "Introverts/Extroverts", text: "Introverts/Extroverts"},
                    {value: "Risk-Takers/Risk-Avoiders", text: "Risk-Takers/Risk-Avoiders"},
                    { value: "Spenders/Savers", text: "Spenders/Savers" },
                    { value: "Type of High School Education", text: "Type of High School Education" },
                    { value: "Detailed-Oriented/Big-Picture People", text: "Detailed-Oriented/Big-Picture People" },
                    { value: "Volunteers", text: "Volunteers" },
                    { value: "Preferred Type of Interaction", text: "Preferred Type of Interaction" },
                    { value: "Preferred Type of Weather", text: "Preferred Type of Weather" },
                    { value: "Amount Studied Each Week", text: "Amount Studied Each Week" },
                    { value: "Morning/Night Person", text: "Morning/Night Person" },
                    { value: "Create Company/Work for Existing Company", text: "Create Company/Work for Existing Company" },
                    { value: "Favorite Type of Dancing", text: "Favorite Type of Dancing" },
                    { value: "Most Proficient Musical Instrument", text: "Most Proficient Musical Instrument" },
                    { value: "Favorite Type of Music", text: "Favorite Type of Music" },
                    { value: "Political Party", text: "Political Party" },
                    { value: "Interest in an International Job", text: "Interest in an International Job" },
                    { value: "Industry of Interest", text: "Industry of Interest" },
                    { value: "Business Area of Interest", text: "Business Area of Interest" },
                ],
                id: 25,
                isRequired: true
            },
            {
                name: "Eldest Child Option1",
                title: "Group of Eldest Children or Non-Eldest Children?",
                type: "dropdown",
                id: 26,
                visibleIf: '{ranking1} == "Eldest Child"',
                choices: [
                    {value: "Yes", text: "Eldest Children"},
                    {value: "No", text: "Non-Eldest Child"},
                ]
            },
            {
                name: "Youngest Child Option1",
                title: "Group of Youngest Children or Non-Youngest Children?",
                type: "dropdown",
                id: 27,
                visibleIf: '{ranking1} == "Youngest Child"',
                choices: [
                    {value: "Yes", text: "Youngest Children"},
                    {value: "No", text: "Non-Youngest Child"},
                ]
            },
            {
                name: "Continent Born On Option1",
                title: "Group of People From What Continent?",
                type: "dropdown",
                id: 28,
                visibleIf: '{ranking1} == "Continent Born On"',
                choices: [
                    {value: "Asia", text: "Asia"},
                    {value: "North America", text: "North America"},
                    {value: "South America", text: "South America"},
                    {value: "Africa", text: "Africa"},
                    {value: "Europe", text: "Europe"},
                    {value: "Antarctica", text: "Antarctica"},
                    {value: "Australia", text: "Australia"},
                ],
            },
            {
                name: "siblingsOption1",
                title: "Group of People With How Many Siblings?",
                type: "dropdown",
                id: 29,
                visibleIf: '{ranking1} == "Number of Siblings"',
                choices: [
                    {value: "0", text: "0 Siblings"},
                    {value: "1-2", text: "1-2 Siblings"},
                    {value: "3+", text: "3+ Siblings"},
                ],
            },
            {
                name: "languagesOption1",
                title: "Group of People Able to Speak How Many Languages?",
                type: "dropdown",
                id: 30,
                visibleIf: '{ranking1} == "Number of Languages Spoken"',
                choices: [
                    {value: "1", text: "1 Language"},
                    {value: "2", text: "2 Languages"},
                    {value: "3", text: "3 Languages"},
                    {value: "4+", text: "4+ Languages"},
                ],
            },
            {
                name: "extrovertOption1",
                title: "Group Of Introverts, Extroverts, or Ambiverts?",
                type: "dropdown",
                id: 31,
                visibleIf: '{ranking1} == "Introverts/Extroverts"',
                choices: [
                    {value: "Extrovert", text: "Extroverts"},
                    {value: "Introvert", text: "Introverts"},
                    {value: "Ambivert", text: "Ambiverts"},
                ],
            },
            {
                name: "riskOption1",
                title: "Group of Risk-Takers or Risk-Avoiders?",
                type: "dropdown",
                id: 32,
                visibleIf: '{ranking1} == "Risk-Takers/Risk-Avoiders"',
                choices: [
                    {value: "Risk-taker", text: "Risk-Takers"},
                    {value: "Risk-avoider", text: "Risk-Avoiders"},
                ],
            },{
                name: "spenderOption1",
                title: "Group of Savers or Spenders?",
                type: "dropdown",
                id: 33,
                visibleIf: '{ranking1} == "Spenders/Savers"',
                choices: [
                    { value: "Spender", text: "Spenders" },
                    { value: "Saver", text: "Savers" },
                ],
            },
            {
                name: "highSchoolOption1",
                title: "Group of People with What High School Education?",
                type: "dropdown",
                id: 34,
                visibleIf: '{ranking1} == "Type of High School Education"',
                choices: [
                    { value: "Home-schooled", text: "Home-schooled" },
                    { value: "Private", text: "Private" },
                    { value: "Public", text: "Public" },
                ],
            },
            {
                name: "detailOption1",
                title: "Group of Detail-Oriented or Big-Picture People?",
                type: "dropdown",
                id: 35,
                visibleIf: '{ranking1} == "Detailed-Oriented/Big-Picture People"',
                choices: [
                    { value: "Detail-oriented", text: "Detail-Oriented" },
                    { value: "Big-picture", text: "Big-Picture" },
                ],
            },
            {
                name: "volunteerOption1",
                title: "Group of Volunteers or Non-Volunteers?",
                type: "dropdown",
                id: 36,
                visibleIf: '{ranking1} == "Volunteers"',
                choices: [
                    { value: "Yes", text: "Volunteers" },
                    { value: "No", text: "Non-Volunteers" },
                ],
            },
            {
                name: "interactionOption1",
                title: "Group of People that Prefer What Type of Interaction?",
                type: "dropdown",
                id: 37,
                visibleIf: '{ranking1} == "Preferred Type of Interaction"',
                choices: [
                    { value: "One-on-one interactions", text: "One-on-one interactions" },
                    { value: "Larger group gatherings", text: "Larger group gatherings" },
                ],
            },
            {
                name: "weatherOption1",
                title: "Group of People with Which Preferred Type of Weather?",
                type: "dropdown",
                id: 38,
                visibleIf: '{ranking1} == "Preferred Type of Weather"',
                choices: [
                    { value: "Warm and sunny", text: "Warm and sunny" },
                    { value: "Overcast and rainy", text: "Overcast and rainy" },
                    { value: "Cold and snowy", text: "Cold and snowy" },
                ],
            },{
                name: "studyOption1",
                title: "Group of People That Study How Much a Week?",
                type: "dropdown",
                id: 39,
                visibleIf: '{ranking1} == "Amount Studied Each Week"',
                choices: [
                    { value: "0-5 hours", text: "0-5 hours" },
                    { value: "5-9 hours", text: "5-9 hours" },
                    { value: "10+ hours", text: "10+ hours" },
                ],
            },
            {
                name: "morningOption1",
                title: "Group of Morning or Night People?",
                type: "dropdown",
                id: 40,
                visibleIf: '{ranking1} == "Morning/Night Person"',
                choices: [
                    { value: "Morning", text: "Morning People" },
                    { value: "Night", text: "Night Owls" },
                ],
            },
            {
                name: "companyOption1",
                title: "Group Wanting to Start Their Own Company or Work for an Existing Company?",
                type: "dropdown",
                id: 41,
                visibleIf: '{ranking1} == "Create Company/Work for Existing Company"',
                choices: [
                    { value: "Create my own company", text: "Create Company" },
                    { value: "Work for an existing company", text: "Work for Company" },
                    { value: "Not sure", text: "Not sure" },
                ],
            },{
                name: "dancingOption1",
                title: "Group of People with What Favorite Type of Dancing?",
                type: "dropdown",
                id: 42,
                visibleIf: '{ranking1} == "Favorite Type of Dancing"',
                choices: [
                    { value: "Ballet", text: "Ballet" },
                    { value: "Ballroom", text: "Ballroom" },
                    { value: "Hip Hop", text: "Hip Hop" },
                    { value: "Ethnic", text: "Ethnic" },
                    { value: "Line/Square Dancing", text: "Line/Square Dancing" },
                    { value: "Rock Dancing", text: "Rock Dancing" },
                    { value: "Don't dance", text: "Don't dance" },
                ],
            },
            {
                name: "instrumentOption1",
                title: "Group of People Able to Play What Musical Instrument?",
                type: "dropdown",
                id: 43,
                visibleIf: '{ranking1} == "Most Proficient Musical Instrument"',
                choices: [
                    { value: "Piano", text: "Piano" },
                    { value: "Guitar", text: "Guitar" },
                    { value: "Brass", text: "Brass" },
                    { value: "Percussion", text: "Percussion" },
                    { value: "Woodwind", text: "Woodwind" },
                    { value: "Strings (classical)", text: "Strings (classical)" },
                    { value: "Quintet", text: "Quintet" },
                    { value: "None", text: "None" },
                ],
            },
            {
                name: "musicOption1",
                title: "Group of People with What Favorite Type of Music?",
                type: "dropdown",
                id: 44,
                visibleIf: '{ranking1} == "Favorite Type of Music"',
                choices: [
                    { value: "Rap", text: "Rap" },
                    { value: "Pop", text: "Pop" },
                    { value: "Country", text: "Country" },
                    { value: "Rock", text: "Rock" },
                    { value: "Classical/Jazz", text: "Classical/Jazz" },
                    { value: "Folk/Indie", text: "Folk/Indie" },
                ],
            },{
                name: "partyOption1",
                title: "Group of People with What Political Party Affiliation?",
                type: "dropdown",
                id: 45,
                visibleIf: '{ranking1} == "Political Party"',
                choices: [
                    { value: "Democratic", text: "Democratic" },
                    { value: "Republican", text: "Republican" },
                    { value: "Independent", text: "Independent" },
                ],
            },
            {
                name: "internationalJobOption1",
                title: "Group of People Interested or Not Interested in an International Job?",
                type: "dropdown",
                id: 46,
                visibleIf: '{ranking1} == "Interest in an International Job"',
                choices: [
                    { value: "Yes", text: "Interested" },
                    { value: "No", text: "Not Interested" },
                ],
            },
            {
                name: "industryOption1",
                title: "Group of People with What Industry of Interest?",
                type: "dropdown",
                id: 47,
                visibleIf: '{ranking1} == "Industry of Interest"',
                choices: [
                    { value: "Finance", text: "Finance" },
                    { value: "Technology", text: "Technology" },
                    { value: "Education", text: "Education" },
                    { value: "Health", text: "Health" },
                    { value: "Media", text: "Media" },
                    { value: "Nonprofit", text: "Nonprofit" },
                ],
            },
            {
                name: "businessAreaOption1",
                title: "Group of People with What Business Area of Interest?",
                type: "dropdown",
                id: 48,
                visibleIf: '{ranking1} == "Business Area of Interest"',
                choices: [
                    { value: "Entrepreneurship", text: "Entrepreneurship" },
                    { value: "Finance", text: "Finance" },
                    { value: "Human Resources", text: "Human Resources" },
                    { value: "Logistics", text: "Logistics" },
                    { value: "Management/Leadership", text: "Management/Leadership" },
                    { value: "Marketing", text: "Marketing" },
                    { value: "Operations", text: "Operations" },
                    { value: "Social Entrepreneurship", text: "Social Entrepreneurship" },
                    { value: "Strategy", text: "Strategy" },
                    { value: "Technology", text: "Technology" },
                ],
            },
            {
                name: "rankingOptionDistributed1",
                title: "Do You Want These People On the Same Team or Distributed Across the Teams?",
                type: "dropdown",
                id: 98,
                isRequired: true,
                choices: [
                    {value: "Same", text: "All on the Same Team"},
                    {value: "Distributed", text: "Distributed Across All Teams"},
                ]
            },
        ]
    }
    ]
};

function CreateTeamsPage(){

    const navigate = useNavigate();
    const [currSurvey] = useState(JSON.parse(localStorage.getItem("survey")));
    const [creatingTeams, setCreatingTeams] = useState(false);

    const handleSubmit = async (combinedJson) => {
        try {
            const response = await axios.post(`${backendPort}/createTeams`, combinedJson);


        } catch (error) {
            console.error('Error storing survey data:', error);
        }
    };

    function saveSubmissionResults(surveyPreferences) {
        console.log(surveyPreferences);
        let surveyInfo = JSON.parse(localStorage.getItem("survey"));

        const finalSurveyPreferences = {};
        
        for (const key in surveyPreferences) {
            if (key.endsWith("Option0")) {
                // const newKey = key.replace("Option0", "rankingOption0");
                const newKey = "rankingOption0";
                finalSurveyPreferences[newKey] = surveyPreferences[key];
            } else if (key.endsWith("Option1")) {
                const newKey = "rankingOption1";
                finalSurveyPreferences[newKey] = surveyPreferences[key];
            } else {
                finalSurveyPreferences[key] = surveyPreferences[key];
            }
        }

        console.log(finalSurveyPreferences);

        let combinedJson = {
            surveyInfo,
            finalSurveyPreferences
        };
        console.log(combinedJson);
        setCreatingTeams(true);
        handleSubmit(combinedJson);
        setCreatingTeams(false)
        navigate('/adminHome');
    }
    const survey = new Model(surveyJson);
    survey.title = "Team Matching Survey";
    const surveyComplete = useCallback((sender) => {

        saveSubmissionResults(
            sender.data
        )
    }, []);

    survey.onComplete.add(surveyComplete);

    return(
        <div className="container">
            <NavBar />
            {creatingTeams ? (
                    <p>Creating Teams...</p>
                ) : (
                <Survey model={survey} />
                )}
        </div>
    );
}

export default CreateTeamsPage;