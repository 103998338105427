import React, { useState } from "react";
import ELCRoom from '../ELCRoom.jpg';
import { TEInput, TERipple } from "tw-elements-react";
import Navbar from '../components/NavBar';
import { useNavigate } from "react-router-dom";

export default function AdminLogin(): JSX.Element {
  const [username, setUsername] = useState(""); // State for username
  const [password, setPassword] = useState(""); // State for password
  const navigate = useNavigate();

  // Function to handle login button click
  const handleLogin = () => {
    if (username === "admin" && password === "fighton") {
      // Correct username and password
      localStorage.setItem("isLoggedIn", "true"); // Set the flag to indicate the user is logged in
      navigate('/adminHome');
    } else {
      // Incorrect username or password
      alert("Incorrect username or password, please try again.");
    }
  };

  return (
    <section>
      <Navbar></Navbar>
      <div className="container h-full p-10">
        <div className="g-6 flex h-full flex-wrap items-center justify-center text-neutral-800 dark:text-neutral-200">
          <div className="w-full">
            <div className="block rounded-lg bg-white shadow-lg dark:bg-neutral-800">
              <div className="g-0 lg:flex lg:flex-wrap">
                {/* Left column container */}
                <div className="px-4 md:px-0 lg:w-6/12">
                  <div className="md:mx-6 md:p-12">
                    <div className="text-center">
                      <img
                        className="mx-auto w-48"
                        src="https://i.pinimg.com/736x/65/c0/1f/65c01fd960edff474ed8a26280391638--university-of-south-usc-trojans.jpg"
                        alt="logo"
                        style={{
                          width: '25%',
                          height: '25%',
                        }}
                      />
                      <h4 className="mb-12 mt-1 pb-1 text-xl font-semibold">
                        Welcome ELC Admin! Please sign in below
                      </h4>
                    </div>

                    <form>
                      <TEInput
                        type="text"
                        label="Username"
                        className="mb-4"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      ></TEInput>

                      <TEInput
                        type="password"
                        label="Password"
                        className="mb-4"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      ></TEInput>

                      <div className="mb-12 pb-1 pt-1 text-center">
                        <TERipple rippleColor="light" className="w-full">
                          <button
                            className="mb-3 inline-block w-full rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
                            type="button"
                            style={{
                              background:
                                "linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)",
                            }}
                            onClick={handleLogin} // Call the login function on button click
                          >
                            Login
                          </button>
                        </TERipple>
                      </div>
                    </form>
                  </div>
                </div>

                {/* Right column container with background and description */}
                <div className="mb-12 md:mb-0 md:w-8/12 lg:w-6/12">
                  <img
                    src={ELCRoom}
                    alt="ELC Rooms"
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
