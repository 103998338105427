import StudentQuestions from "../components/StudentQuestions";
import NavBar from "../components/NavBar";

function StudentSurvey(){




    return(
        <div className="container">
            <NavBar />
            <StudentQuestions />
        </div>
    );
}

export default StudentSurvey;