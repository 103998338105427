// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submission-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin-top: -100px; /* Adjust the margin-top value to raise or lower the box */
  }
  
  .submission-content {
    text-align: center;
    padding: 60px;
    background-color: #f0f0f0;
    border-radius: 24px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  }
  
  h2 {
    font-size: 40px;
    color: #333;
  }
  
  p {
    font-size: 28px;
    color: #666;
  }`, "",{"version":3,"sources":["webpack://./src/styles/SubmissionPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB,EAAE,0DAA0D;EAChF;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,uCAAuC;EACzC;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,eAAe;IACf,WAAW;EACb","sourcesContent":[".submission-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 100vh;\n    margin-top: -100px; /* Adjust the margin-top value to raise or lower the box */\n  }\n  \n  .submission-content {\n    text-align: center;\n    padding: 60px;\n    background-color: #f0f0f0;\n    border-radius: 24px;\n    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);\n  }\n  \n  h2 {\n    font-size: 40px;\n    color: #333;\n  }\n  \n  p {\n    font-size: 28px;\n    color: #666;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
