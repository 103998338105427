import UserGroupIcon from '@heroicons/react/24/solid/UserGroupIcon';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import {useNavigate} from "react-router-dom";


function ViewEntriesButton(survey) {
    const navigate = useNavigate();
    function handleViewEntriesClick(survey) {
        localStorage.setItem("survey", JSON.stringify(survey));
        console.log(survey);
        navigate('/adminHome/viewEntries');
    }

    return(
    <Button onClick={() => handleViewEntriesClick(survey)}
            startIcon={(
                <SvgIcon fontSize="small">
                    <UserGroupIcon/>
                </SvgIcon>
            )}
            variant="contained"
            style={{backgroundColor: '#e8bc2c', color: 'white'}}
    >
        View Entries
    </Button>
    );

}

export default ViewEntriesButton;
