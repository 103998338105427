import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { subDays, subHours } from 'date-fns';
import ArrowDownOnSquareIcon from '@heroicons/react/24/solid/ArrowDownOnSquareIcon';
import ArrowUpOnSquareIcon from '@heroicons/react/24/solid/ArrowUpOnSquareIcon';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { useSelection } from '../adminHomeSections/UseSelection';
import  SurveysTable  from '../adminHomeSections/SurveysTable';
import { SurveysSearch } from '../adminHomeSections/SurveysSearch';
import { applyPagination } from '../adminHomeSections/ApplyPagination';
import Navbar from '../components/NavBar';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import backendPort from '../port';



const now = new Date();


const Page = () => {

  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // State to store the list of surveys retrieved from the backend
  const [surveys, setSurveys] = useState([
  ]);



// State to track whether the surveys are loading
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${backendPort}/adminSurveys`)
        .then(response => {
          console.log(response.data);
          setSurveys(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error(error);
        });
  }, []);



  // useEffect(() => {
  //   // Fetch surveys when the component mounts
  //
  //   //fetchSurveys();
  //   setLoading(false);
  // }, []);


    const handlePageChange = useCallback(
      (event, value) => {
        setPage(value);
      },
      []
    );

    const handleRowsPerPageChange = useCallback(
      (event) => {
        setRowsPerPage(event.target.value);
      },
      []
    );

  return (
      <>
        <Navbar></Navbar>
          <title>
            Surveys | Devias Kit
          </title>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8
          }}
        >
          <Container maxWidth="xl">
            <Stack spacing={3}>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={4}
              >
                <Stack spacing={1}>
                  <Typography variant="h4">
                    Surveys
                  </Typography>
                </Stack>
                <div>
                  <Button
                    startIcon={(
                      <SvgIcon fontSize="small">
                        <PlusIcon />
                      </SvgIcon>
                    )}
                    variant="contained"
                    style={{ backgroundColor: '#C41E3A', color: 'white' }}
                    onClick={() => navigate('/adminSurvey')}
                  >
                    Create New Survey
                  </Button>
                </div>
              </Stack>
              <SurveysSearch />
              {loading ? (
                  <p>Loading surveys...</p>
              ) : surveys.length === 0 ? (
                  <p>No surveys created</p>
              ) : (
                <SurveysTable
                  count={surveys.length}
                  items={surveys}
                  // onDeselectAll={surveySelection.handleDeselectAll}
                  // onDeselectOne={surveySelection.handleDeselectOne}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  onSelectAll={surveys.handleSelectAll}
                  // onSelectOne={surveySelection.handleSelectOne}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  // selected={surveySelection.selected}
                />
              )}
            </Stack>
          </Container>
        </Box>
      </>
    );
  };

export default Page;