import 'survey-core/modern.css';
import axios from 'axios';
import { Model } from 'survey-core';
import {Survey} from "survey-react-ui";
import {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import backendPort from "../port";


const surveyJson = {
    elements: [{
        name: "FirstName",
        title: "What is your first name?",
        type: "text",
        id: 1,
        isRequired: true
    }, {
        name: "LastName",
        title: "What is your last name?",
        type: "text",
        id: 2,
        isRequired: true
    },{
        name: "email",
        title: "What is your usc email?",
        type: "text",
        id: 3,
        isRequired: true
    },{
        name: "eldestChild",
        title: "Are you the eldest child in your family?",
        type: "radiogroup",
        choices: [
            { value: true, text: "Yes" },
            { value: false, text: "No" },
        ],
        id: 4,
        isRequired: true
    },{
        name: "youngestChild",
        title: "Are you the youngest child in your family?",
        type: "radiogroup",
        choices: [
            { value: true, text: "Yes" },
            { value: false, text: "No" },
        ],
        id: 5,
        isRequired: true
    },{
        name: "continent",
        title: "What continent were you born on?",
        type:"radiogroup",
        choices: [
            { value: "Asia", text: "Asia" },
            { value: "North America", text: "North America" },
            { value: "South America", text: "South America" },
            { value: "Africa", text: "Africa" },
            { value: "Europe", text: "Europe" },
            { value: "Antarctica", text: "Antarctica" },
            { value: "Australia", text: "Australia" },
        ],
        id: 6,
        isRequired: true
    },{
        name: "siblings",
        title: "How many siblings do you have?",
        type:"radiogroup",
        choices: [
            {value: "0", text: "0"},
            {value: "1-2", text: "1-2"},
            {value: "3+", text: "3+"},
        ],
        id: 7,
        isRequired: true
    },{
        name: "languages",
        title: "How many languages do you speak?",
        type:"radiogroup",
        choices: [
            {value: "1", text: "1"},
            {value: "2", text: "2"},
            {value: "3", text: "3"},
            {value: "4+", text: "4+"},
        ],
        id: 8,
        isRequired: true
    }, {
        name: "extrovert",
        title: "Would you describe yourself as an extrovert or introvert?",
        type: "radiogroup",
        choices: [
            { value: "Extrovert", text: "Extrovert" },
            { value: "Introvert", text: "Introvert" },
            { value: "Ambivert", text: "Ambivert" },
        ],
        id: 9,
        isRequired: true
    },{
        name: "risk",
        title: "Would you describe yourself as a risk-taker or risk-avoider?",
        type: "radiogroup",
        choices: [
            { value: "Risk-taker", text: "Risk-taker" },
            { value: "Risk-avoider", text: "Risk-avoider" },
        ],
        id: 10,
        isRequired: true
    },{
        name: "spender",
        title: "Are you a spender or saver?",
        type: "radiogroup",
        choices: [
            { value: "Spender", text: "Spender" },
            { value: "Saver", text: "Saver" },
        ],
        id: 11,
        isRequired: true
    },{
        name: "highSchool",
        title: "What type of High School education did you have?",
        type: "radiogroup",
        choices: [
            { value: "Home-schooled", text: "Home-schooled" },
            { value: "Private", text: "Private" },
            { value: "Public", text: "Public" },
        ],
        id: 12,
        isRequired: true
    },{
        name: "detail",
        title: "Are you more detail-oriented or a big-picture person?",
        type: "radiogroup",
        choices: [
            { value: "Detail-oriented", text: "Detail-oriented" },
            { value: "Big-picture", text: "Big-picture" },
        ],
        id: 13,
        isRequired: true
    },{
        name: "volunteer",
        title: "Do you participate in any volunteer or community service activties?",
        type: "radiogroup",
        choices: [
            { value: 1, text: "Yes" },
            { value: 0, text: "No" },
        ],
        id: 14,
        isRequired: true
    },{
        name: "interactionTypes",
        title: "Do you prefer one-on-one interactions or larger group gatherings?",
        type: "radiogroup",
        choices: [
            { value: "One-on-one interactions", text: "One-on-one interactions" },
            { value: "Larger group gatherings", text: "Larger group gatherings" },
        ],
        id: 15,
        isRequired: true
    },{
        name: "favoriteWeather",
        title: "What type of weather do you prefer?",
        type: "radiogroup",
        choices: [
            { value: "Warm and sunny", text: "Warm and sunny" },
            { value: "Overcast and rainy", text: "Overcast and rainy" },
            { value: "Cold and snowy", text: "Cold and snowy" },
        ],
        id: 16,
        isRequired: true
    },{
        name: "studyTimes",
        title: "How many hours per week do you typically spend studying during a semester?",
        type: "radiogroup",
        choices: [
            { value: "0-5 hours", text: "0-5 hours" },
            { value: "5-9 hours", text: "5-9 hours" },
            { value: "10+ hours", text: "10+ hours" },
        ],
        id: 17,
        isRequired: true
    },{
        name: "morning",
        title: "Are you a morning or night person?",
        type: "radiogroup",
        choices: [
            { value: "Morning", text: "Morning" },
            { value: "Night", text: "Night" },
        ],
        id: 18,
        isRequired: true
    },{
        name: "ownCompany",
        title: "Do you want to create your own company or work for an existing company?",
        type: "radiogroup",
        choices: [
            { value: "Create my own company", text: "Create my own company" },
            { value: "Work for an existing company", text: "Work for an existing company" },
            { value: "Not sure", text: "Not sure" },
        ],
        id: 19,
        isRequired: true
    },{
        name: "dancing",
        title: "What's your favorite type of dancing?",
        type: "radiogroup",
        choices: [
            { value: "Ballet", text: "Ballet" },
            { value: "Ballroom", text: "Ballroom" },
            { value: "Hip Hop", text: "Hip Hop" },
            { value: "Ethnic", text: "Ethnic" },
            { value: "Line/Square Dancing", text: "Line/Square Dancing" },
            { value: "Rock Dancing", text: "Rock Dancing" },
            { value: "Don't dance", text: "Don't dance" },
        ],
        id: 20,
        isRequired: true
    },{
        name: "musicalInstrument",
        title: "What type of musical instrument can you play? (if you play multiple, select the one you are most proficient in)",
        type: "radiogroup",
        choices: [
            { value: "Piano", text: "Piano" },
            { value: "Guitar", text: "Guitar" },
            { value: "Brass", text: "Brass" },
            { value: "Percussion", text: "Percussion" },
            { value: "Woodwind", text: "Woodwind" },
            { value: "Strings (classical)", text: "Strings (classical" },
            { value: "None", text: "None" },
        ],
        id: 21,
        isRequired: true
    },{
        name: "favoriteMusic",
        title: "What's your favorite type of music?",
        type: "radiogroup",
        choices: [
            { value: "Rap", text: "Rap" },
            { value: "Pop", text: "Pop" },
            { value: "Country", text: "Country" },
            { value: "Rock", text: "Rock" },
            { value: "Classical/Jazz", text: "Classical/Jazz" },
            { value: "Folk/Indie", text: "Folk/Indie" },
        ],
        id: 22,
        isRequired: true
    },{
        name: "politicalParty",
        title: "What political party do you identify with the most?",
        type: "radiogroup",
        choices: [
            { value: "Democratic", text: "Democratic" },
            { value: "Republican", text: "Republican" },
            { value: "Independent", text: "Independent" },
        ],
        id: 23,
        isRequired: true
    },{
        name: "internationalJob",
        title: "Are you ever interested in working an international job?",
        type: "radiogroup",
        choices: [
            { value: true, text: "Yes" },
            { value: false, text: "No" },
        ],
        id: 24,
        isRequired: true
    },{
        name: "industry",
        title: "What industry are you most interested in working in?",
        type: "radiogroup",
        choices: [
            { value: "Finance", text: "Finance" },
            { value: "Technology", text: "Technology" },
            { value: "Education", text: "Education" },
            { value: "Health", text: "Health" },
            { value: "Media", text: "Media" },
            { value: "Nonprofit", text: "Nonprofit" },
        ],
        id: 25,
        isRequired: true
    },{
        name: "businessArea",
        title: "What business area are you primarily interested in?",
        type: "radiogroup",
        choices: [
            { value: "Entrepreneurship", text: "Entrepreneurship" },
            { value: "Finance", text: "Finance" },
            { value: "Human Resources", text: "Human Resources" },
            { value: "Logistics", text: "Logistics" },
            { value: "Management/Leadership", text: "Management/Leadership" },
            { value: "Marketing", text: "Marketing" },
            { value: "Operations", text: "Operations" },
            { value: "Social Entrepreneurship", text: "Social Entrepreneurship" },
            { value: "Strategy", text: "Strategy" },
            { value: "Technology", text: "Technology" },
        ],
        id: 26,
        isRequired: true
    }]
};



const handleSubmit = async (combinedJson) => {
    try {
      const response = await axios.post(`${backendPort}/storeSurvey`, combinedJson);
      
    } catch (error) {
      console.error('Error storing survey data:', error);
    }
  };

export default function StudentQuestions(){

    const navigate = useNavigate();
    const [currSurvey] = useState(JSON.parse(localStorage.getItem("survey")));
    function saveSurveyResults(surveyContent) {
        let surveyInfo = JSON.parse(localStorage.getItem("survey"));
        let combinedJson = {
            surveyInfo,
            surveyContent
        };
        try{
            handleSubmit(combinedJson); 
        }catch(error){
            console.log('Error storing survey: ', error)
        }
        navigate('/studentSurvey/submissionPage');
    }
    const survey = new Model(surveyJson);
    survey.title = "Team Matching Survey";
    const surveyComplete = useCallback((sender) => {

        saveSurveyResults(
            sender.data
        )
    }, []);

    survey.onComplete.add(surveyComplete);

    return(
        <Survey model={survey} />
    );

}