import UserGroupIcon from '@heroicons/react/24/solid/UserGroupIcon';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import {useNavigate} from "react-router-dom";
import axios from "axios";


function ViewTeamsButton(survey) {
    const navigate = useNavigate();

    function handleViewTeamsClick(survey) {
        localStorage.setItem("survey", JSON.stringify(survey)); //set the current survey
        navigate('/adminHome/viewTeams');
    }

    return (
        <Button onClick={() => handleViewTeamsClick(survey)}
                startIcon={(
                    <SvgIcon fontSize="small">
                        <UserGroupIcon/>
                    </SvgIcon>
                )}
                variant="contained"
                style={{backgroundColor: '#26a7de', color: 'white'}}
        >
            View Teams
        </Button>
    );
}

export default ViewTeamsButton;