import AdminQuestions from "../components/AdminQuestions";
import NavBar from "../components/NavBar";

function AdminSurvey(){




    return(
        <div className="container">
            <NavBar />
            <AdminQuestions />
        </div>
    );
}

export default AdminSurvey;