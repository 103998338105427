import PlayIcon from '@heroicons/react/24/solid/PlayIcon';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import {useNavigate} from "react-router-dom";

const BeginSurveyButton = (survey) => {

    const navigate = useNavigate();
    async function handleSurveyClick(survey){
        console.log(survey);
        localStorage.setItem("survey", JSON.stringify(survey));
        navigate('/studentSurvey');
    }

    return(
    <Button
        startIcon={(
            <SvgIcon fontSize="small">
                <PlayIcon/>
            </SvgIcon>
        )}
        variant="contained"
        style={{backgroundColor: '#26a7de', color: 'white'}}
        onClick={ () => handleSurveyClick(survey)}
    >
        Begin Survey
    </Button>);

};

export default BeginSurveyButton;