import 'survey-core/defaultV2.css';

import { Model } from 'survey-core';
import {Survey} from "survey-react-ui";
import axios from 'axios';
import {useCallback, useState, Component} from "react";
import {useNavigate} from "react-router-dom";
import $ from "jquery";
import * as SurveyCore from "survey-core";
import { jqueryuidatepicker } from "surveyjs-widgets";
import "jquery-ui-dist/jquery-ui.css";
import backendPort from "../port"


const surveyJson = {
    elements: [{
        name: "date",
        title: "What is the date of the class?",
        type: "datepicker",
        isRequired: true
    }, {
        name: "time",
        title: "What time is the class?",
        type: "text",
        inputType: "time",
        isRequired: true,
        inputMask: "99:99 AM/PM", // Input masking for time format
      },
        {
            name: "elc",
            title: "Select the ELC classroom for this survey",
            type: "dropdown",
            isRequired: true,
            choices: ["JFF", "JKP", "Zoom"],
        }
    ]
};


export default function AdminQuestions(){

    const navigate = useNavigate();
    function formatDate(inputDate) {
        const options = { weekday: 'long', month: 'long', day: 'numeric' };
        const dateObj = new Date(inputDate);
        return dateObj.toLocaleDateString(undefined, options);
    }
    
    function formatTime(inputTime) {
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        const timeObj = new Date(`1970-01-01 ${inputTime}`);
        const stringTime = timeObj.toLocaleTimeString(undefined, options);
        return stringTime.charAt(0) === '0' ? stringTime.slice(1) : stringTime;
    }

    const handleSubmit = async (surveyJSON) => {
        try {
          const response = await axios.post(`${backendPort}/storeSurveyCreation`, surveyJSON);
          
          // You can optionally reset the form here
          
        } catch (error) {
          console.error('Error storing survey data:', error);
        }
    };

    function saveSurveyResults(data) {
        // Format the date and time
        const formattedDate = formatDate(data.date);
        const formattedTime = formatTime(data.time);
    
        // Construct the final JSON
        const surveyJSON = {
          createdTeams: false,
          date: formattedDate,
          time: formattedTime,
          elc: data.elc,
        };

        console.log(surveyJSON);

        try{
            handleSubmit(surveyJSON); 
        }catch(error){
            console.log('Error storing survey: ', error)
        }

        

        alert("Survey Successfully Created!");
        navigate('/adminHome');
    }
    
    
    window["$"] = window["jQuery"] = $;
    require("jquery-ui-dist/jquery-ui.js");
    jqueryuidatepicker(SurveyCore);

    const survey = new Model(surveyJson);
    survey.title = "Create New Survey";
    const surveyComplete = useCallback((sender) => {
        saveSurveyResults(
            sender.data
        )
    }, []);
    
    survey.onComplete.add(surveyComplete);

    return(
        <Survey model={survey} />
    );

}