import UserGroupIcon from '@heroicons/react/24/solid/UserGroupIcon';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import {useNavigate} from "react-router-dom";



function CreateTeamsButton(survey) {

    const navigate = useNavigate();

    function handleCreateTeamsClick(survey) {
        localStorage.setItem("survey", JSON.stringify(survey));
        navigate('/adminHome/createTeamsPage');
    };
    return (
        <Button onClick={() => handleCreateTeamsClick(survey)}
                startIcon={(
                    <SvgIcon fontSize="small">
                        <UserGroupIcon/>
                    </SvgIcon>
                )}
                variant="contained"
                style={{backgroundColor: 'blue', color: 'white'}}
        >
            Create Teams
        </Button>
    );
}

export default CreateTeamsButton;