import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { Scrollbar } from '../components/Scrollbar';
import { getInitials } from '../adminHomeSections/GetInitials';
import BeginSurveyButton from '../components/BeginSurveyButton';
import {useNavigate} from "react-router-dom";




function SurveyTimeTable(props){
    const {
      count = 0,
      items = [],
      onPageChange = () => {},
      onRowsPerPageChange,
      page = 0,
      rowsPerPage = 0
    } = props;



  return (
      <Card>
        <Scrollbar>
          <Box sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '25px', fontWeight: 'bold'}}>
                      What time are you visiting the ELC?
                  </TableCell>
                  <TableCell>

                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((survey) => {

                  return (
                    <TableRow
                      hover
                      key={survey.id}
                    >
                      <TableCell>
                        <Stack
                          alignItems="center"
                          direction="row"
                          spacing={2}
                        ><Avatar src={survey.avatar} sx={{ width: 60, height: 60, backgroundColor: '#C41E3A'}}>
                          {getInitials(survey.date)}
                        </Avatar>
                          <Typography variant="subtitle2" sx={{ fontSize: '18px', fontWeight: 'bold'}}>
                            {survey.date} at {survey.time}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <BeginSurveyButton survey={survey}></BeginSurveyButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    );
};

SurveyTimeTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};

export default SurveyTimeTable;