import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import {useEffect} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import backendPort from '../port';

const mapping = {
    "eldestChild": "Eldest Child",
    "youngestChild": "Youngest Child",
    "continent": "Continent Born On",
    "siblings": "Number of Siblings",
    "languages": "Number of Languages Spoken",
    "extrovert": "Introverts/Extroverts",
    "risk": "Risk-Takers/Risk-Avoiders",
    "spender": "Spenders/Savers",
    "highSchool": "Type of High School Education",
    "detail": "Detailed-Oriented/Big-Picture People",
    "volunteer": "Volunteers",
    "interactionTypes": "Preferred Type of Interaction",
    "favoriteWeather": "Preferred Type of Weather",
    "studyTimes": "Amount Studied Each Week",
    "morning": "Morning/Night Person",
    "ownCompany": "Create Company/Work for Existing Company",
    "dancing": "Favorite Type of Dancing",
    "musicalInstrument": "Most Proficient Musical Instrument",
    "favoriteMusic": "Favorite Type of Music",
    "politicalParty": "Political Party",
    "internationalJob": "Interest in an International Job",
    "industry": "Industry of Interest",
    "businessArea": "Business Area of Interest",
    "overall": "Overall"
};


function TeamViewer() {
    const navigate = useNavigate();
    const [isViewingTrait, setIsViewingTrait] = useState(false); // Initialize as false to view the trait initially
    const [isEditingRooms, setIsEditingRooms] = useState(false);
    const [editedTeamsData, setEditedTeamsData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let surveyInfo = JSON.parse(localStorage.getItem("survey")); //pass the student Info to the backend call
        axios.get(`${backendPort}/viewTeams`,{  params:surveyInfo})
            .then(response => {
                console.log(response.data);
                setEditedTeamsData(response.data); //set the team data
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const toggleView = () => {
        setIsViewingTrait(!isViewingTrait);
    };

    const recreateTeams = async () => {
        try {
            //grab survey data
            const surveyData = JSON.parse(localStorage.getItem('survey'));
            console.log(surveyData);
            //make call to the backend
            const response = await axios.post(`${backendPort}/recreate`, surveyData);
            navigate('/adminHome');
            // Handle the response as needed
        } catch (error) {
            console.error(error);
            // Handle any errors that occur during the asynchronous operations
        }
    };

    const toggleEditRooms = () => {
        if (isEditingRooms) {
            setIsEditingRooms(false);
            setIsViewingTrait(false); // Set isViewingTrait to false
            console.log("MAKING BACKEND CALL");
            axios.post(`${backendPort}/editTeam`, editedTeamsData)
                .then(response => {
                    console.log(response.data);
                    // Handle the response as needed
                })
                .catch(error => {
                    console.error(error);
                });
        } else {
            setIsEditingRooms(true);
        }
    };

    return (
        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <button
                className="bg-blue-500 hover-bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={toggleView}
            >
                {isViewingTrait ? 'View Room Number' : 'View Trait'}
            </button>
            <button
                className={`bg-${isEditingRooms ? 'green-500' : 'blue-500'} hover-bg-blue-700 text-white font-bold py-2 px-4 rounded`}
                onClick={toggleEditRooms}
                style={{ marginLeft: '30px' }}
            >
                {isEditingRooms ? 'Save Rooms' : 'Enter Room Numbers'}
            </button>
            <button
                className={`bg-gray-500 hover-bg-blue-700 text-white font-bold py-2 px-4 rounded`}
                onClick={recreateTeams}
                style={{ marginLeft: '30px' }}
            >
                Recreate Teams
            </button>
            <div className="flex flex-wrap">
                {loading ? (
                    <p>Loading teams...</p>
                ) : (
                    (isEditingRooms
                        ? editedTeamsData.rooms.map((team, index) => (
                            <div key={index} className="w-full sm:w-1/2 md:w-1/3 p-4">
                                <div className="border border-gray-300 rounded-lg p-4">
                                    <h3 className="text-lg font-semibold mb-2">
                                        <input
                                            type="text"
                                            value={team.breakout_room}
                                            onChange={(e) => {
                                                const newEditedTeamsData = { ...editedTeamsData }; // Make a shallow copy of the object
                                                newEditedTeamsData.rooms[index].breakout_room = e.target.value;
                                                setEditedTeamsData(newEditedTeamsData);
                                            }}
                                            style={{ border: '2px solid blue' }}
                                        />
                                        <div>
                                            {mapping[team.question] + " = " +team.required_answer}
                                        </div>
                                    </h3>
                                    <hr
                                        style={{
                                            backgroundColor: 'gray',
                                            height: '2px',
                                            margin: '10px 0',
                                        }}
                                    />
                                    <div className="divide-y divide-gray-300">
                                        <ul>
                                            {team.students.map((student, i) => (
                                                <li key={i} className="py-2">
                                                    {student}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))
                        : editedTeamsData.rooms.map((team, index) => (
                            <div key={index} className="w-full sm:w-1/2 md:w-1/3 p-4">
                                <div className="border border-gray-300 rounded-lg p-4">
                                    <h3 className="text-lg font-semibold mb-2">
                                        {isViewingTrait ?   team.breakout_room + ":\u2002 " + mapping[team.question] + " = " +team.required_answer : team.breakout_room}
                                    </h3>
                                    <hr
                                        style={{
                                            backgroundColor: 'gray',
                                            height: '2px',
                                            margin: '10px 0',
                                        }}
                                    />
                                    <div className="divide-y divide-gray-300">
                                        <ul>
                                            {team.students.map((student, i) => (
                                                <li key={i} className="py-2">
                                                    {student}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))))}
            </div>
        </div>
    );
}

function ViewTeamsPage() {
    return (
        <div>
            <NavBar />
            <TeamViewer />
        </div>
    );
}

export default ViewTeamsPage;