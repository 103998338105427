import React, { useState } from 'react';
import NavBar from '../components/NavBar';




const teamsData = [
    {
        room: 'Room A',
        students: ['Student 1', 'Student 2', 'Student 3'],
    },
    {
        room: 'Team 2',
        students: ['Student 4', 'Student 5', 'Student 6'],
    },
    {
        room: 'Team 3',
        students: ['Student 7', 'Student 8', 'Student 9'],
    },
    {
        room: 'Team 4',
        students: ['Student 10', 'Student 11', 'Student 12'],
    }
]

function TeamViewer() {
    const [isViewingTrait, setIsViewingTrait] = useState(true);
    const [buttonText, setButtonText] = useState('View Trait');

    const toggleView = () => {
        setIsViewingTrait(!isViewingTrait);
        setButtonText(isViewingTrait ? 'View Room Number' : 'View Trait');
    };

    return (
        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={toggleView}
            >
                {buttonText === 'View Trait' ? 'View Room Number' : 'View Trait'}
            </button>
            <div className="flex flex-wrap">
                {teamsData.map((team, index) => (
                    <div key={index} className="w-full sm:w-1/2 md:w-1/3 p-4">
                        <div className="border border-gray-300 rounded-lg p-4">
                            <h3 className="text-lg font-semibold mb-2">{team.room}</h3>
                            <hr
                                style={{
                                    backgroundColor: 'gray', // Darker color
                                    height: '2px',           // Thicker line
                                    margin: '10px 0',        // Adjust spacing
                                }}
                            />
                            <div className="divide-y divide-gray-300">
                                <ul>
                                    {team.students.map((student, i) => (
                                        <li key={i} className="py-2">
                                            {student}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}



    function ViewTeams() {
    return (
        <div>
            <NavBar />
            <TeamViewer />
        </div>
    );
}

export default ViewTeams;


// function TeamViewer() {
//     const [isViewingTrait, setIsViewingTrait] = useState(true);
//     const [isEditingRooms, setIsEditingRooms] = useState(false);
//     const [buttonText, setButtonText] = useState('View Trait');
//     const [editedTeamsData, setEditedTeamsData] = useState(teamsData);

//     const toggleView = () => {
//         setIsViewingTrait(!isViewingTrait);
//         setButtonText(isViewingTrait ? 'View Room Number' : 'View Trait');
//     };

//     const toggleEditRooms = () => {
//         setIsEditingRooms(!isEditingRooms);
//     };

//     const saveRooms = () => {
//         // Save the edited data to the JSON being displayed
//         // You can save it to a backend or local storage as needed
//         // For demonstration, we will update the original data
//         teamsData = editedTeamsData;
//         toggleEditRooms(); // Toggle isEditingRooms back to false
//     };

//     return (
//         <div className="bg-white p-4 rounded-lg shadow-md mb-4">
//             <button
//                 className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//                 onClick={toggleView}
//             >
//                 {buttonText}
//             </button>

//             {isEditingRooms && (
//                 <button
//                     className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
//                     onClick={saveRooms}
//                 >
//                     Save Rooms
//                 </button>
//             )}

//             <div className="flex flex-wrap">
//                 {isEditingRooms
//                     ? editedTeamsData.map((team, index) => (
//                         <div key={index} className="w-full sm:w-1/2 md:w-1/3 p-4">
//                             <div className="border border-gray-300 rounded-lg p-4">
//                                 <h3 className="text-lg font-semibold mb-2">
//                                     <input
//                                         type="text"
//                                         value={team.room}
//                                         onChange={(e) => {
//                                             const newEditedTeamsData = [...editedTeamsData];
//                                             newEditedTeamsData[index].room = e.target.value;
//                                             setEditedTeamsData(newEditedTeamsData);
//                                         }}
//                                     />
//                                 </h3>
//                                 <hr
//                                     style={{
//                                         backgroundColor: 'gray',
//                                         height: '2px',
//                                         margin: '10px 0',
//                                     }}
//                                 />
//                                 <div className="divide-y divide-gray-300">
//                                     <ul>
//                                         {team.students.map((student, i) => (
//                                             <li key={i} className="py-2">
//                                                 {student}
//                                             </li>
//                                         ))}
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     ))
//                     : teamsData.map((team, index) => (
//                         <div key={index} className="w-full sm:w-1/2 md:w-1/3 p-4">
//                             <div className="border border-gray-300 rounded-lg p-4">
//                                 <h3 className="text-lg font-semibold mb-2">{team.room}</h3>
//                                 <hr
//                                     style={{
//                                         backgroundColor: 'gray',
//                                         height: '2px',
//                                         margin: '10px 0',
//                                     }}
//                                 />
//                                 <div className="divide-y divide-gray-300">
//                                     <ul>
//                                         {team.students.map((student, i) => (
//                                             <li key={i} className="py-2">
//                                                 {student}
//                                             </li>
//                                         ))}
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     ))}
//             </div>
//         </div>
//     );
// }