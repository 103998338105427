import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";

import Navbar from '../components/NavBar';

const tableData = [
    {
        title: "Are you the Eldest Child?",
        data: [
            { name: "Yes", Instrument: 50 },
            { name: "No", Instrument: 45 },
        ],
        color: "#82ca9d" // Green color
    },
    {
        title: "Are you the Youngest Child",
        data: [
            { name: "Yes", Instrument: 50 },
            { name: "No", Instrument: 45 },
        ],
        color: "#8884d8" // Purple color
    },
    {
        title: "What Contienent were you born in ?",
        data: [
            { name: "Asia", Instrument: 9 },
            { name: "North America", Instrument: 7 },
            { name: "South America", Instrument: 6 },
            { name: "Africa", Instrument: 8 },
            { name: "Europe", Instrument: 9 },
            { name: "Antarctica", Instrument: 7 },
            { name: "Australia", Instrument: 6 },],
        color: "#ffc658" // Yellow color
    },
    {
        title: "How Many Siblings do you have?",
        data: [
            { name: "0", Instrument: 7 },
            { name: "1-2", Instrument: 8 },
            { name: "3+", Instrument: 9 },
        ],
        color: "#ff6b6b" // Red color
    }
];

export default function SurveyResponse() {
    return (
        <div>
            <Navbar />
            <div className="container mx-auto p-4">
                <h1 className="text-3xl font-semibold mb-4">Survey Response: Monday 10 am </h1>
                {tableData.map((table, index) => (
                    <div key={index} className="mb-6">
                        <h2 className="text-xl font-semibold mb-4 text-center">{table.title}</h2>
                        <div className="bg-white rounded-lg shadow p-4">
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart
                                    data={table.data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="Instrument" fill={table.color} />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
