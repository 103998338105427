import React from "react";
import { Navigate, Route, Routes} from "react-router-dom";
import AdminLogin from "./pages/AdminLogin";
import HomePage from "./pages/HomePage";
import AdminHome from "./pages/AdminHome";
import StudentSurvey from "./pages/StudentSurvey";
import StudentSurveys from "./pages/StudentSurveys";
import './App.css';
import SubmissionPage from "./pages/SubmissionPage";
import ViewTeamsPage from "./pages/ViewTeamsPage";
import ViewEntriesPage from "./pages/ViewEntriesPage";
import CreateTeamsPage from "./pages/CreateTeamsPage";
import AdminSurvey from "./pages/AdminSurvey";
import SurveyResponse from "./pages/SurveyResponse";
import ViewTeams from "./pages/ViewTeams";


function App() {
  return (
      <div>
        <Routes>
          {/* Root pages, located in /pages/ */}
          <Route path="/" element={<HomePage />}/>
          <Route path="/adminLogin" element={<AdminLogin />} />
          <Route path="/home" element={<HomePage />}/>
          <Route path="/adminHome" element={<AdminHome/>}/>
          <Route path="/adminSurvey" element={<AdminSurvey/>}/>
          <Route path="/studentSurvey" element={<StudentSurvey />}/>
          <Route path ="/adminHome/createTeamsPage" element={<CreateTeamsPage />} />
            <Route path="/studentSurveys" element={<StudentSurveys />}/>
          <Route path ="/SurveyResponse" element={<SurveyResponse />} />
          <Route path ="/ViewTeams" element={<ViewTeams />} />
          <Route path="/studentSurveys" element={<StudentSurveys />}/>
            <Route path ="/studentSurvey/submissionPage" element={<SubmissionPage />}/>
            <Route path ="/adminHome/viewTeams" element={<ViewTeamsPage />} />
            <Route path ="/adminHome/viewEntries" element={<ViewEntriesPage />} />
          <Route path="*" element={<Navigate to="/" home />} />
        </Routes>
      </div>
  );
}

export default App;
