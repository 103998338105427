import React from 'react';
import '../styles/homepage.css'; // Import your CSS file
import Navbar from '../components/NavBar'; // Import your NavBar component
import StudentSurveys from '../pages/AdminLogin';
import { Link } from "react-router-dom";
function HomePage() {
    return (
        <div>
           <Navbar></Navbar>
            <div className="sect sect--padding-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="site">
                                <h1 className="site__title">Welcome to the ELC!</h1>
                                <h2 className="site__subtitle">Team Matching Survey</h2>
                                <div className="site__box-link">
                                    <Link to="./StudentSurveys">
                                        <a className="btn btn--width" href="">Begin Survey</a>
                                    </Link>
                                </div>
                                <img className="site__img" src="https://image.ibb.co/c7grYb/image3015.png" alt="Survey Image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;