import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {useNavigate} from "react-router-dom";
import { applyPagination } from '../adminHomeSections/ApplyPagination';
import  SurveyTimeTable  from '../components/SurveyTimeTable';
import NavBar from "../components/NavBar";
import axios from "axios";
import backendPort from '../port';


const data = [{}];
const useCustomers = (page, rowsPerPage) => {
    return useMemo(
      () => {
        return applyPagination(data, page, rowsPerPage);
      },
      [page, rowsPerPage]
    );
  };

  const useCustomerIds = (customers) => {
    return useMemo(
      () => {
        return customers.map((customer) => customer.id);
      },
      [customers]
    );
  };

function StudentSurveys(){

    const navigate = useNavigate();
    // State to store the list of surveys retrieved from the backend
    const [surveys, setSurveys] = useState([]);
    // State to track whether the surveys are loading
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(true);
    const customers = useCustomers(page, rowsPerPage);
    const customersIds = useCustomerIds(customers);
    const handlePageChange = useCallback(
        (event, value) => {
          setPage(value);
        },
        []
      );
  
      const handleRowsPerPageChange = useCallback(
        (event) => {
          setRowsPerPage(event.target.value);
        },
        []
      );

    useEffect(() => {
        axios.get(`${backendPort}/studentSurveys`)
            .then(response => {
                console.log(response.data);
                setSurveys(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    function handleSurveyClick(surveyInfo) {
        localStorage.setItem("surveyInfo", JSON.stringify(surveyInfo));
        navigate('/studentSurvey');
    }

    return(
        <div>
            <NavBar />
            {loading ? (
                <p>Loading surveys...</p>
            ) : surveys.length === 0 ? (
                <p>No surveys available</p>
            ) : (
                <SurveyTimeTable
                count={surveys.length}
                items={surveys}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            )}

        </div>
    );
}

export default StudentSurveys;